import React from "react"
import { css } from "@emotion/react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div css={css`
    position: relative;
    padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
    padding-top: 0px;
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;

    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
  `}>
    <iframe
      src={videoSrcURL}
      title={videoTitle ? videoTitle : "Orpheus Competition Music Video"}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video